<template>
  <div>
    <headerNav></headerNav>
    <div class="header">
      <div class="header-main flex-i">
        <img class="header-logo" src="../../assets/images/login/logo.png" alt="">
        <span class="header-title">榆阳产业服务平台营销商城</span>
        <div class="">
          <!-- <input class="header-search" v-model="searchContent" type="text">
          <button class="sch_button" @click="goList">搜索</button> -->
          <!-- <div class="header-search-btn">搜索</div> -->
        </div>
      </div>
      <!-- <img src="" alt="">
      <span>榆阳产业服务平台商城</span> -->
    </div>
      <div class="main">
          <ul class="shop-box">
              <li class="shop-item" v-for="(item, index) in shopList" :key="index">
                  <div class="shop-icon"></div>
                  <div class="shop-main">
                      <div style="display: flex;align-items: center;">
                          <span class="shop-name by1">{{item.shopName}}</span>
                          <span class="recommend">推荐</span>
                      </div>
                      <div style="margin-top: 13px;">好评率: 100%</div>
                      <div style="margin-top: 10px;">
                          <img class="icon" src="../../assets/images/buyIcon.png" alt="">
                          <span style="margin: 0 15px 0 10px;">商品</span>
                          <img class="icon" src="../../assets/images/plIcon.png" alt="">
                          <span style="margin-left: 10px;">评论 (0)</span>
                      </div>
                  </div>
              </li>
          </ul>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import headerNav from './../../components/headerNav'

export default {
  name: 'common',
  data(){
      return{
          shopList: []
      }
  },
  components: {
      headerNav
  }, 
  mounted(){
      this.getData()
  },
  methods:{
      getData() {
          this.qa.queryShopList({}).then(res => {
            if(res.respCode == "0000"){
               this.shopList = res.data.list
            } else {
                this.$message.error(res.respDesc);
            }
        })
      }
   
  }
}
</script>
<style scoped>
  .header {
    width: 100%;
    height: 126px;
    background: #fff;
  }
  .header-main {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
  }
  .header-logo {
    width: 60px;
    height: 60px;
  }
  .header-title {
    font-size: 28px;
    color: 333;
    font-weight: 600;
    margin: 0 37px 0 10px;
  }
  .header-search-box {
    width: 718px;
    height: 46px;
    display: flex;
    align-items: center;
    border: 1px solid #84C754;
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 21px;
  }
  .header-search {
    width: 570px;
    border: 0;
    
  }
  .header-search-btn {
    width: 106px;
    height: 44px;
    line-height: 44p[x];
    border: 0 8px 8px 0;
  }
  .sch_button {
    width: 106px;
    height: 46px;
    border: 0;
    margin-right: -1px;
    background: #84C754;
    font-weight: 800;
    color: #FFFFFF;
    border-radius: 0px 8px 8px 0px;
    cursor: pointer;
  }
    .main {
        width: 1200px;
        margin: 20px auto;
        box-sizing: border-box;
        padding: 25px;
        background: #fff;
    }
    
    .shop-item {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        text-align: left;
        align-items: center;
        margin: 15px 0;
    }

    .shop-icon {
        width: 100px;
        height: 100px;
        background: url(../../assets/images/shoplogo.png);
        background-size: 100% 100%;
    }
    .shop-main {
        margin-left: 15px;
    }
    .icon {
        width: 20px;
        height: 20px;
    }
    .recommend {
        padding: 3px 5px;
        background: #84C754;
        color: #fff;
        margin-left: 5px;
        font-size: 12px;
    }
    .shop-box {
        display: flex;
        flex-wrap: wrap;
    }
    .shop-name {
        display: inline-block;
        max-width: 400px;
    }
</style>